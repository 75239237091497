import { FC } from 'react';
import { PrismicRichText, PrismicRichTextProps } from '@prismicio/react';

import { ChildrenRenderer } from '@lib/renderers/ChildrenRenderer';
import { TEXT_COLOR_LABELS } from '@lib/slice-machine/constants';
import { linkResolver } from '@lib/slice-machine/linkResolver';
import { SxStyles } from '@lib/theme/sxTheme';
import { SxTheme } from '@lib/theme/types';
import { Box } from '@mui/system';
import { LinkButton, LinkButtonProps } from '@ui/buttons/LinkButton';
import { ButtonVariant, TextColor } from '@lib/slice-machine/types';
import { FilledLinkToWebField, RichTextField } from '@prismicio/types';

interface Props extends Omit<PrismicRichTextProps, 'field' | 'linkResolver'> {
  render?: RichTextField[];
  linkBtnProps?: {
    linkAsButton?: boolean;
    variant?: ButtonVariant;
  } & Partial<LinkButtonProps>;
  containerSx?: SxTheme;
}

export const CustomRichText: FC<Props> = (props) => {
  const { linkBtnProps = {}, containerSx, ...rest } = props;

  return (
    <Box sx={styles.merge('container', containerSx)}>
      <PrismicRichText
        field={props.render}
        linkResolver={linkResolver}
        components={{
          label: ({ node, children, key }) => (
            <ChildrenRenderer
              key={key}
              Wrapper={Box}
              wrapperProps={{
                sx: {
                  color:
                    node?.data?.label &&
                    TEXT_COLOR_LABELS.includes(node.data.label as TextColor)
                      ? node?.data?.label
                      : undefined,
                },
                component: 'span',
              }}
            >
              {children}
            </ChildrenRenderer>
          ),
          hyperlink: ({ node, children, key }) => {
            const {
              linkAsButton = false,
              variant = 'contained',
              ...restLinkBtnProps
            } = linkBtnProps;
            const href = linkResolver(node.data);

            if (linkAsButton) {
              return (
                <LinkButton
                  key={key}
                  href={href}
                  variant={variant}
                  target={(node.data as FilledLinkToWebField)?.target}
                  sx={styles.merge(variant === 'outlined' && 'outlinedButton')}
                  {...restLinkBtnProps}
                >
                  {children}
                </LinkButton>
              );
            }
          },
        }}
        {...rest}
      />
    </Box>
  );
};

const styles = new SxStyles({
  container: {
    '& * a': {
      textDecoration: 'none',
    },
    '& p, & ol, & ul': {
      mb: 0,
      lineHeight: 1.3,
    },
    '& > p:first-of-type, & > ol:first-of-type, & > ul:first-of-type': {
      mt: 0,
    },
  },
  outlinedButton: {
    bgcolor: 'white',

    '&:hover': {
      bgcolor: 'white',
    },
  },
});
